import LargeRightCaret from '@components/icons/LargeRightCarret';
import SmallLogo from '@components/icons/SmallLogo';

type InspectionsButtonProps = {
	topLabel: string;
	bottomLabel?: string;
	hasCaret: boolean;
};
export function MenuPromoBannerCtInspections({
	topLabel = '',
	bottomLabel,
	hasCaret = true,
}: InspectionsButtonProps) {
	return (
		<>
			<div className="flex items-center gap-x-4 ">
				<div className="h-10 w-10 text-cti-default">
					<SmallLogo />
				</div>
				<div className={`flex ${bottomLabel ? 'flex-col' : ''}`}>
					<div className="mb-1 text-xl font-semibold leading-tight">
						{topLabel}
					</div>
					<div className="text-sm font-medium leading-tight">
						{bottomLabel}
					</div>
				</div>
			</div>
			{hasCaret ? (
				<span>
					<LargeRightCaret />
				</span>
			) : null}
		</>
	);
}
