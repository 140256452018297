function Notification() {
	return (
		<svg
			width="19"
			height="20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle cx="9.5" cy="10" r="9.5" fill="#EE6700" />
			<path
				d="M10.188 12.531h-1.36l-.406-7.953h2.164l-.399 7.953Zm-1.86 2.492c0-.385.102-.677.305-.875.203-.203.492-.304.867-.304.37 0 .654.104.852.312.197.209.296.498.296.867 0 .37-.101.665-.304.883-.198.214-.48.32-.844.32-.37 0-.659-.104-.867-.312-.203-.208-.305-.505-.305-.89Z"
				fill="#fff"
			/>
		</svg>
	);
}

export default Notification;
