function LargeRightCarret() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="32"
			fill="none"
		>
			<path stroke="currentColor" stroke-width="3" d="m2 31 12-15L2 1" />
		</svg>
	);
}

export default LargeRightCarret;
