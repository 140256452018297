type Props = {
	color?: string;
};
function Hamburger({ color = '#000' }: Props) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="41"
			height="23"
			fill="none"
			focusable="false"
		>
			<path
				stroke={color}
				strokeLinecap="round"
				strokeWidth="2"
				d="M39.71 1.875H11.526M39.71 21.125H11.526M39.71 11.5H1.238"
			/>
		</svg>
	);
}

export default Hamburger;
