function SmallLogo() {
	return (
<svg fill="none" viewBox="0 0 40 40">
  <path d="M20 40c11.046 0 20-8.954 20-20S31.046 0 20 0 0 8.954 0 20s8.954 20 20 20Z" fill="#fff"/>
  <path d="M23.736 27.903h-7.353c-4.367 0-7.92-3.552-7.92-7.919 0-4.366 3.553-7.92 7.92-7.92h3.45l-1.574 2.96h-1.876c-2.075 0-4.76 1.851-4.76 4.96 0 2.995 2.575 5.01 4.76 5.01h7.353v2.909Zm-18.412 0a.244.244 0 0 1-.217-.36l1.345-2.55H8.41a9.034 9.034 0 0 0 3.13 2.91H5.324Z" fill="currentColor"/>
  <path d="m33.098 15.028 1.307-2.39c.225-.411.146-.544-.322-.544H21.554l-1.535 2.934h5.442v12.907c1.176-.183 2.215-.786 3.16-1.397v-11.51h4.477Z" fill="currentColor"/>
</svg>	);
}

export default SmallLogo;
