import type { SupportedCountries } from '@store/user';
import {
	aboutUsLinks,
	auctionBuyerLandingPageLinks,
	auctionListingLinks,
	auctionSellerLandingPageLinks,
	classicCarDealersLinks,
	classicCarMakesLinks,
	classicMotorbikeMakesLinks,
	contactLinks,
	magazineLinks,
	magazineSubscriptionLinks,
	mediaDataLinks,
	motorbikeDealersLinks,
	newsletterLinks,
	pressLinks,
	privateSellerHowItWorksLinks,
	privateSellerPriceListLinks,
	privateSellerSellNowLinks,
	searchLinks,
	shopLinks,
	traderPriceListLinks,
	traderSellNowLinks,
	traderTestimonialsLinks,
	vehicleStorageFacilitiesLinks,
	type I18nLinks,
} from './localizedLinks';
import type { VehicleAdType } from './zodVehicleAds';

type LinkItem = {
	i18nKey: string;
	href: I18nLinks;
};

type NavListItemWithoutSubmenu = {
	id: string;
	i18nKey: string;
	hasSubmenu: false;
} & LinkItem;

type NavListItemWithSubmenu = {
	id: string;
	i18nKey: string;
	hasSubmenu: true;
	sections: {
		id: string;
		i18nKey: string;
		items: LinkItem[];
	}[];
};
const countryCodes: SupportedCountries[] = [
	'uk',
	'au',
	'fr',
	'de',
	'ch',
	'at',
	'es',
	'nl',
	'it',
];
function getAuctionListingLink(
	vehicleType: VehicleAdType['type'],
	countryCode: SupportedCountries
) {
	return auctionListingLinks[vehicleType][countryCode];
}
function generateAuctionHrefObject(
	vehicleType: VehicleAdType['type']
): Record<SupportedCountries, string> {
	return Object.fromEntries(
		countryCodes.map((countryCode) => [
			countryCode,
			getAuctionListingLink(vehicleType, countryCode)?.toString() ?? '',
		])
	) as Record<SupportedCountries, string>;
}

export const navigationItems = {
	navList: [
		{
			id: 'auctions',
			// t('menu.auctions', 'Auctions')
			i18nKey: 'menu.auctions',
			hasSubmenu: true,
			sections: [
				{
					id: 'auctionsTitle',
					// t('menu.auctions/title', 'Auctions on Classic Trader')
					i18nKey: 'menu.auctions/title',
					items: [
						{
							// t('menu.auction/car-auctions', 'Car auctions')
							i18nKey: 'menu.auction/car-auctions',
							href: generateAuctionHrefObject('CarAd'),
						},
						{
							// t('menu.auction/motorbike-auctions', 'Motorbike auctions')
							i18nKey: 'menu.auction/motorbike-auctions',
							href: generateAuctionHrefObject('MotorbikeAd'),
						},
						{
							// t('menu.auction/seller-info', 'Sell at auction')
							i18nKey: 'menu.auction/seller-info',
							href: auctionSellerLandingPageLinks,
						},
						{
							// t('menu.auction/buyer-info', 'Buy at auction')
							i18nKey: 'menu.auction/buyer-info',
							href: auctionBuyerLandingPageLinks,
						},
					],
				},
			],
		},
		{
			id: 'buy',
			// t('menu.buy', 'Buy')
			i18nKey: 'menu.buy',
			hasSubmenu: true,
			sections: [
				{
					id: 'cars',
					// t('menu.buy/cars', 'Cars')
					i18nKey: 'menu.buy/cars',
					items: [
						{
							// t('menu.buy/cars/buyCars', 'Buy cars')
							i18nKey: 'menu.buy/cars/buyCars',
							href: searchLinks.CarAd,
						},
						{
							// t('menu.buy/cars/classicCarDealers', 'Classic car dealers')
							i18nKey: 'menu.buy/cars/classicCarDealers',
							href: classicCarDealersLinks,
						},
						{
							// t('menu.buy/cars/allMakes', 'All makes')
							i18nKey: 'menu.buy/cars/allMakes',
							href: classicCarMakesLinks,
						},
					],
				},
				{
					id: 'motorcycles',
					// t('menu.motorcycles', 'Motorcycles')
					i18nKey: 'menu.motorcycles',
					items: [
						{
							// t('menu.buy/motorcycles/buy', 'Buy motorcycles')
							i18nKey: 'menu.buy/motorcycles/buy',
							href: searchLinks.MotorbikeAd,
						},
						{
							// t('menu.buy/motorcycles/dealers', 'Motorbike dealers')
							i18nKey: 'menu.buy/motorcycles/dealers',
							href: motorbikeDealersLinks,
						},
						{
							// t('menu.buy/motorcycles/allMakes', 'All makes')
							i18nKey: 'menu.buy/motorcycles/allMakes',
							href: classicMotorbikeMakesLinks,
						},
					],
				},
			],
		},
		{
			id: 'sell',
			// t('menu.sell', 'Sell')
			i18nKey: 'menu.sell',
			hasSubmenu: true,
			sections: [
				{
					id: 'private',
					// t('menu.sell/private', 'Private')
					i18nKey: 'menu.sell/private',
					items: [
						{
							// t('menu.sell/private/sellNow', 'Sell now')
							i18nKey: 'menu.sell/private/sellNow',
							href: privateSellerSellNowLinks,
						},
						{
							// t('menu.sell/private/howItWorks', 'How it works')
							i18nKey: 'menu.sell/private/howItWorks',
							href: privateSellerHowItWorksLinks,
						},
						{
							// t(	'menu.sell/private/priceList', 'Price list')
							i18nKey: 'menu.sell/private/priceList',
							href: privateSellerPriceListLinks,
						},
					],
				},
				{
					id: 'dealers',
					// t('menu.sell/dealers', 'Dealers')
					i18nKey: 'menu.sell/dealers',
					items: [
						{
							// t('menu.sell/dealers/sellNow', 'Sell now')
							i18nKey: 'menu.sell/dealers/sellNow',
							href: traderSellNowLinks,
						},
						{
							// t('menu.sell/dealers/testimonials', 'Testimonials')
							i18nKey: 'menu.sell/dealers/testimonials',
							href: traderTestimonialsLinks,
						},
						{
							// t('menu.sell/dealers/priceList', 'Price list')
							i18nKey: 'menu.sell/dealers/priceList',
							href: traderPriceListLinks,
						},
					],
				},
			],
		},
		{
			id: 'mag',
			// t('menu.magazine', 'Magazine')
			i18nKey: 'menu.magazine',
			hasSubmenu: false,
			href: magazineLinks,
		},
		{
			id: 'more',
			// t('menu.more', 'More')
			i18nKey: 'menu.more',
			hasSubmenu: true,
			sections: [
				{
					id: 'subscribe',
					// t('menu.more/subscribe', 'Subscribe')
					i18nKey: 'menu.more/subscribe',
					items: [
						{
							// t('menu.more/subscribe/newsletter', 'Newsletter')
							i18nKey: 'menu.more/subscribe/newsletter',
							href: newsletterLinks,
						},
						{
							// t('menu.more/subscribe/print-magazine', 'Print magazine')
							i18nKey: 'menu.more/subscribe/print-magazine',
							href: magazineSubscriptionLinks,
						},
						{
							// t('menu.more/subscribe/advertise', 'Advertise')
							i18nKey: 'menu.more/subscribe/advertise',
							href: mediaDataLinks,
						},
					],
				},
				{
					id: 'further',
					// t('menu.more/further', 'Furter')
					i18nKey: 'menu.more/further',
					items: [
						{
							// t('menu.more/further/shop', 'Shop')
							i18nKey: 'menu.more/further/shop',
							href: shopLinks,
						},
						{
							// t('menu.more/further/vehicleStorages', 'Vehicle storages')
							i18nKey: 'menu.more/further/vehicleStorages',
							href: vehicleStorageFacilitiesLinks,
						},
						{
							// t('menu.more/further/aboutUs', 'About us')
							i18nKey: 'menu.more/further/aboutUs',
							href: aboutUsLinks,
						},
						{
							// t('menu.more/further/press', 'Press')
							i18nKey: 'menu.more/further/press',
							href: pressLinks,
						},
						{
							// t('menu.more/further/contact', 'Contact')
							i18nKey: 'menu.more/further/contact',
							href: contactLinks,
						},
					],
				},
			],
		},
	],
} as const satisfies {
	navList: Array<NavListItemWithSubmenu | NavListItemWithoutSubmenu>;
};
